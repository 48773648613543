
/**/
.navbar-default .navbar-nav>li>.anchor {
    color: #ffffff;
}

.navbar-nav > li > .anchor {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 21px;
}
.nav > li  > .anchor {
    position: relative;
    display: block;
    padding: 10px 15px;
}
@media (min-width: 768px){
    .navbar-nav>li> .anchor {
        padding-top: 19.5px;
        padding-bottom: 19.5px;
    }
}
.navbar-nav > li a,
.navbar-nav > li .anchor {
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
}
.navbar-nav > li:hover > a,
.navbar-nav > li:hover > .anchor {
    color: white;
}
.navbar-nav > li .fa {
    padding-right: 5px;
}

/**/
.dark-blue.navbar-toggle
{
    border-color: white;
    color: white;
}

.dark-blue.navbar-toggle > a
{
    color: white;
}



.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > .anchor:hover
{
    color: white;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form
{
    border-color: white;
    text-align: left;
}
.navbar {
    z-index:100!important;
}

.navbar.navbar-default {
    position: fixed;
    top:0px;
    left:0px;
    right: 0px;

}
.navbar .navbar-nav{

    display: inline-block;
    float: none;
}

.navbar-stay-horizontal > li
{
    display: inline-block;
}


.navbar .navbar-collapse
{
    text-align: center;
}


#myNavbar
{
    margin-left: 45px;
}

/* remove outer padding */
.sidebar-1.container-main .row
{
    padding: 0px;
    margin: 0px;
}
.sidebar-1.navbar-nav
{
    float: left;
    margin: 0;
}
.container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header
{
    margin-right: 0;
    margin-left: 0;
}
.sidebar-1.navbar-nav > li
{
    float: left;
}
/*Remove rounded coners*/
nav.sidebar.navbar
{
    border-radius: 0;
    position: fixed;
    height: calc( 100vh - 65px ) ;
    top: 0;
    bottom: 0;
    overflow: auto;
}
nav.sidebar, .container-main
{
    -webkit-transition: margin 200ms ease-out;
    -moz-transition: margin 200ms ease-out;
    -o-transition: margin 200ms ease-out;
    transition: margin 200ms ease-out;
}
/* Icons */
.menu-icon
{
    font-size: 20px;
}

.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand
{
    margin-left: -15px;
}

/* Colors */
.navbar-m2p
{
    background-color: white;
    border-color: black;
}

.navbar-m2p span, .navbar-m2p a
{
    color: auto;
}

.nav li > a:hover, .nav .open > a:hover,
.nav li > a:focus, .nav .open > a:focus,
.nav li > a:active, .nav .open > a:active
{
    background-color: rgba(241, 57, 57, 0.9);
}

/* borda menu active */
.navbar-m2p .navbar-nav .active a
{
    margin-left: -1px;
    border-left: 5px solid #7EA927;
}

/* Hamburger */

nav:hover .forAnimate
{
    opacity: 1;
}

.nav li.separator
{
    padding: 10px 15px;
    text-transform: uppercase;
    background-color: rgb(241, 57, 57);
    color: black;
}

/* .....NavBar: Icon only with coloring/layout.....*/

/*small/medium side display*/

/*Allow main to be next to Nav*/
.container-main
{
    /*position: absolute;*/
    width: calc(100% - 60px); /*keeps 100% minus nav size*/
    margin-left: 60px;
    float: right;
}

/*lets nav bar to be showed on mouseover*/
nav.sidebar:hover + .container-main
{
    margin-left: 250px;
}

/*Center Brand*/
nav.sidebar.navbar.sidebar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand
{
    margin-left: 0px;
}

/*Center Brand*/
nav.sidebar .navbar-brand, nav.sidebar .navbar-header
{
    text-align: center;
    width: 100%;
    margin-left: 0px;
    font-size: 25px;
    line-height: 27px;
}

/*Center Icons*/
nav.sidebar a
{
    padding-right: 13px;
}

/* Colors/style dropdown box*/
nav.sidebar .navbar-nav .open .dropdown-menu
{
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/*allows nav box to use 100% width*/
nav.sidebar .navbar-collapse, nav.sidebar .container-fluid
{
    margin-top: -1px;
    padding: 0 0px 0 0px;
}

/*gives sidebar width/height*/
nav.sidebar
{
    margin-top:65px;
    width: 820px;
    padding:10px;
    height: 100%;
    margin-left: -740px;
    float: left;
    z-index: 50;
    margin-bottom: 0px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
}

/*give sidebar 100% width;*/
nav.sidebar li
{
    width: 100%;
}

/* Move nav to full on mouse over*/
nav.sidebar:hover
{
    box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);
    margin-left: 0px;
}

/*for hiden things when navbar hidden*/
.forAnimate
{
    opacity: 0;
}
/* .....NavBar: Fully showing nav bar..... */

@media (min-width: 1850px)
{

    /*Allow main to be next to Nav*/
    .container-main
    {
        width: calc(100% - 820px); /*keeps 100% minus nav size*/
        margin-left: 820px;
    }
    /*Show all nav*/
    nav.sidebar
    {
        margin-left: 0px;
        float: left;
    }
    /*Show hidden items on nav*/
    nav.sidebar .forAnimate
    {
        opacity: 1;
    }
}