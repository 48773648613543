.list-group-item-clickable {
	cursor: pointer;
}
.list-group-item-clickable:hover {
	box-shadow: 2px 6px 10px #888888;
	z-index: 100
}
.list-group-item.active {
	border-color: green;
}
.btn.btn-none {
  color:red;
}
.btn.btn-none:hover {
  border:1px solid black;
  color:initial;
}