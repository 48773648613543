/*These are a sort of loading thing*/
.btn {
  border: none;
}
.border-blue {
  border: 3px solid rgb(50, 94, 234);
}
.utm-raise {
  background: white;
  display: inline-block;
  position: relative;
}
.utm-raise.border-blue:after {
  content: attr(data-title);
  z-index: -1;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: -10px;
  left: -10px;
  border: 3px solid rgb(50, 94, 234);
}

.wave-dots {
  position:relative;
  text-align:center;
  width:100px;
  height:100px;
  margin-left: auto;
  margin-right: auto;

}
.wave-dots > .dot {
  display:inline-block;
  width:12px;
  height:12px;
  border-radius:50%;
  margin-right:3px;
  background:#303131;
  animation: wave 1.3s linear infinite;
}
.wave-dots.sm > .dot {
  width:8px;
  height:8px;
}
.wave-dots.xs > .dot {
  width:5px;
  height:5px;
}
.wave-dots > .dot:nth-child(2) {
  animation-delay: -1.1s;
}
.wave-dots > .dot:nth-child(3) {
  animation-delay: -0.9s;
}
@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}

.edit-symbol {
   color: #2f5ce6;
   cursor: pointer;
}
.edit-symbol:hover {
   color: green;
}
.btn-outline-primary {
  background-color: transparent;
  color: rgb(52, 92, 235)!important;
}
.nav-tabs>li.active>.anchor, .nav-tabs>li.active>.anchor:hover, .nav-tabs>li.active>.anchor:focus {
    color: #2c3e50;
    background-color: #ffffff;
    border: 1px solid #ecf0f1;
    border-bottom-color: transparent;
    cursor: default;
}
.nav-tabs>li>.anchor{
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.nav>li>.anchor {
    position: relative;
    display: block;
    padding: 10px 15px;
}
body, html, #root {
    height:100%;
    font-family: Roboto, sans-serif;
}
span.grippy {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin:4px;
}
.raise-element {
  box-shadow: 0 4px 11px 0 rgba(0,0,0,0.13);
  border: 1px solid rgba(22,28,45,0.10);
  border-radius: 7px;
}
span.grippy,
span.grippy::before {
  background-image: radial-gradient(black 40%, transparent 40%);
  background-size: 4px 4px;
  background-position: 0 50%;
  background-repeat: repeat-x;
}

span.grippy::before {
  content: '';
  display: block;
  width: 100%;
  height: 10%;
}
.fa-bounce {
  display: inline-block;
  position: relative;
  -moz-animation: fa-bounce 3.0s infinite linear;
  -o-animation: fa-bounce 3.0s infinite linear;
  -webkit-animation: fa-bounce 3.0s infinite linear;
  animation: fa-bounce 3.0s infinite linear;
}

@-webkit-keyframes fa-bounce {
  0% { top: 0; }
  10% { top: -0.2em; }
  14% { top: -0.3em; }
  20% { top: 0; }
  30% { top: -0.2em; }
  34% { top: -0.3em; }
  40% { top: 0; }
}
@-moz-keyframes fa-bounce {
  0% { top: 0; }
  10% { top: -0.2em; }
  14% { top: -0.3em; }
  20% { top: 0; }
  30% { top: -0.2em; }
  34% { top: -0.3em; }
  40% { top: 0; }
}
@-o-keyframes fa-bounce {
  0% { top: 0; }
  10% { top: -0.2em; }
  14% { top: -0.3em; }
  20% { top: 0; }
  30% { top: -0.2em; }
  34% { top: -0.3em; }
  40% { top: 0; }
}
@-ms-keyframes fa-bounce {
  0% { top: 0; }
  10% { top: -0.2em; }
  14% { top: -0.3em; }
  20% { top: 0; }
  30% { top: -0.2em; }
  34% { top: -0.3em; }
  40% { top: 0; }
}
@keyframes fa-bounce {
  0% { top: 0; }
  10% { top: -0.2em; }
  14% { top: -0.3em; }
  20% { top: 0; }
  30% { top: -0.2em; }
  34% { top: -0.3em; }
  40% { top: 0; }
}

.searchBar:before{
  font-weight: 900;
  font-size: 18px;
  font-family: Font Awesome\ 5 Free;
  color: #869AB8;
  position: relative;
  left: 15px;
  top:30px;
  content: "\f002";    
}

.profileListModal .list-group-item {
  border: none;
  border-bottom: 1px solid #97979721;
  margin-bottom: 2px;
}

.profileListModal .list-group-item:hover {
  background-color: #97979721;
}

.profileListModal .list-group-item .fa {
  float:right;
  color: #325EEA;
  font-weight: bold;
  font-size:18px;
}

.extensionButton {
  background: #FFFFFF;
  color: #869AB8;
  border: 1px solid #869AB8!important;
  font-size: 13px;
  border-radius: 5px!important;
  width: 175px;
  height: 38px;
  margin-top: 20px;
  margin-right: 40px;
}

.extensionButton:hover,.extensionButton:active, .extensionButton:focus  {
  background: whitesmoke;
  color: grey;
}
.extensionButton .fas {
  font-size: 15px;
}

.inline-flex {
  display: inline-flex;
}

.extensionButton .fa-download {
  transform: translateX(-6px);
}

.extensionButton .fa-caret-down {
  transform: translateX(14px);
}


.accountTool {
  cursor: pointer;
  margin-top: 18px;
  margin-right: 60px;
  display: inline-flex;
}

.accountTool .dropdown-menu {
    padding: 0;
    width: 211px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.17);
    border-radius: 5px;
    left: inherit;
    float: right;
    right: 60px;
    z-index: 10000!important;
    /* width: 100px; */
    top: 70px;
}

.accountTool .dropdown-menu li {
    border-bottom: 1px solid rgba(22, 28, 45, 0.15);
    height: 53px;
    line-height: 53px;
    font-weight: 400;
}

.accountTool .dropdown-menu li:hover {
    background-color: whitesmoke;
}

.accountTool .dropdown-menu .fas {
    margin-right: 20px;
    margin-left: 20px;
}

.accountTool .fas {
  color: #869AB8;
  line-height: 42px;
  margin-left: 10px;
  font-size: 20px;
}

.userSettings {
  font-weight: 500;
  display: inline;
  background-color: #BA06C9;
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: 0.6px;
  text-align: center;
  line-height: 44px;
  height: 42px;
  width: 42px;
  border-radius: 50%;
}

.oauth-button {
  margin: 1px;
  border: 1px solid #dedede;
  width: 100%;
  height: 48px;
  line-height: 24px;
  background-color: white;
}

.oauth-button:hover {
  color: black!important;
}

#analyticsButton {
  border: 1px solid #dedede;
  width: 100%;
  height: 48px;
  line-height: 24px;
  background-color: white;
}

.btn-text {
  color: rgb(50, 94, 234);
  background-color: transparent;
}

.btn-text:focus {
  outline: none;
}

.btn-text:hover {
  color: rgb(50, 20, 220);
}


.btn-outline {
    font-weight: 500;
    background: #FFFFFF;
    border: 1px solid #325EEA!important;
    color:  #325EEA;
    outline: none;
    border-radius: 7px!important;
}

.btn-outline:focus {
    outline: none;
    color:  #325EEA;
}

.btn-outline.square {
  border-radius: 4px!important;
}
.btn-outline.red {
  color: #DE4437;
  border: 1px solid #DE4437!important;
}

.btn-outline.red:hover {
  background-color: #DE4437;
  color: whitesmoke;
}
.btn-outline.yellow {
  color: #FFC107;
  border: 1px solid #FFC107!important;
}

.btn-outline.yellow:hover {
  background-color: #FFC107;
  color: whitesmoke;
}

.btn-outline.green {
  border: 1px solid #06C9A8!important;
  border-radius: 4px;
  color: #06C9A8;
}

.btn-outline.green:hover {
  background-color: #06C9A8;
  color: whitesmoke;
}

.loadingContainer {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   width: 50%;
    border-radius: 5px;
    padding-bottom: 20px;
    background-color: white;
}


#analyticsButton:hover {
  color: black!important;
}
.integration-btn {
  width: 100%;
  height: 48px;
  line-height: 24px;
}
#bitlyButton {
  background-color: #EE6123;
  color: white;
}
#bitlyButton:hover {
  background-color: #d86027;
}

#builderButton {
  width: 100%;
  height: 48px;
  line-height: 24px;
}


.dividerText {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 30px;
  margin-bottom: 30px;
}

.dividerText > span {
  position: relative;
  display: inline-block;
}

.dividerText > span:before,
.dividerText > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #f5f5f5;
}

.dividerText > span:before {
  right: 100%;
  margin-right: 15px;
}

.dividerText > span:after {
  left: 100%;
  margin-left: 15px;

}

.navbar {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07);
}

.reorderList {
  margin-bottom: 0;
}

.reorderList .list-group-item:nth-child(2n) {
  background: #F7F9FF;
}

.btn-outline:hover {
  background-color: #2f5ce6;
  color: whitesmoke;
  transition: background-color 0.5s;
}

.tabset {
  margin-bottom: 0;
  padding-left: 0;
    text-align: center;
}
.tabset li {
   text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
    display: inline-block;
    vertical-align: top;
}
.tabset span {
    color: #161C2D;
    font-size: 18px;
    cursor: pointer;
    position: relative;
}
.tabset span:after {
    margin-top: 14px;
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: #325EEA;
    border-radius: 1.875rem;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: background .15s ease-in-out, width .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: background .15s ease-in-out, width .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out, background .15s ease-in-out, width .15s ease-in-out;
    transition: transform .15s ease-in-out, background .15s ease-in-out, width .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.tabset span:hover:after,
.tabset span.over:after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
}

.tabset span.active {
  font-weight: 500;
    color: #325EEA;
}

.tabset span.active:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    background-color: #325EEA;
    color: blue;
}

.btn-success {
  background: #06C9A8;
}

.profilesContainer {
  background: #FFFFFF;
  border: 1px solid rgba(22,28,45,0.10);
  box-shadow: 0 4px 11px 0 rgba(0,0,0,0.13);
  border-radius: 7px;
  height: 320px;
  margin-bottom: 30px;
}

.breadcrumb {
  background: transparent;
  font-weight: 500;
  padding-left: 0;
}


.navbar-default .navbar-nav>li>a {
     color: black;
}

.btn.btn-primary:not(.btn-basic) {
    background-color: #2f5ce6;
}
.btn.btn-primary.active:not(.btn-basic) {
    background-color: #1a242f;
}
.btn.btn-primary:not(.btn-basic):hover {
    background-color: #5c7fed;
}
.btn-primary.light:hover {
    background-color: #e1e9f9!important;
}

.btn.btn-primary:not(.btn-basic):not(.light):hover {
    background-color: #5c7fed;
}

.btn.btn-primary.light {
    font-weight: bold;
    background-color: #eaf0fc;
    color: #2f5ce6;
}

.anchor {
    cursor: pointer;
    color: #1e4dc1;
    text-decoration: none;
}
.anchor:hover {
    color: #2f5ce6;
}

.anchor:hover, .anchor:focus {
    color: #18bc9c;
    text-decoration: underline;
}
.anchor:active, .anchor:hover {
    outline: 0;
}
/*
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
This is for the hover and autocomplete
*/
.on-top{
    z-index: 9999999;
} 

.dark-blue {
    background: #1e4dc1 !important;
    border-color: white;
}

.dark-blue.navbar-toggle {
    border-color: white;
    color: white;
}

.dark-blue.navbar-toggle > a
{
    color: white;
}

.btn-primary a {
    color: white;
}

a.btn
{
    color: 'green';
    padding: 5px 5px;
}

.toaster
{
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 20px;
    z-index: 99999;
}
.ui-output-url-container {
    width: 100%;
    position: relative;
    min-height: 130px;
}

.fieldTitle
{
    float: left;
    width: 20%;
}

.updater
{
    position: fixed;
    top: 10vh;
    right: 3px;
    padding: 7px;
    margin: 5px;
    background: white;
    border-radius: 20px;
    box-shadow: 1px 1px 2px #888;
}

.updater:hover
{
    padding: 8px;
}

.add-one
{
    position: absolute;
    right: 20px;
    margin-top: -25px;
    z-index: 2;
}

.field-row
{
    background: rgb(245, 245, 250);
    border-radius: 20px;
    border: 1px solid white;
    color: black;
}

.white-a
{
    color: white;
    cursor: pointer;
}
.white-a:hover
{
    color: rgb(200, 200, 200);
}

.arrows
{
    padding: 15px 4px 15px 4px;
}

.no-pad
{
    padding: 4px;
}

.selectables
{
    height: 15vh;
    max-height: 500px;
    border-radius: 10px;
    text-align: center;
    background: rgba(100, 100, 100, 0.4);
}

.selectables:nth-child(odd)
{
    background: rgba(100, 100, 100, 0.6);
}

.white-text{
    color: white;
}

.pagination .page-item.side-nav .page-link {
  background: #EBF0FD!important;
  border-radius: 3px!important;
  color: #325EEA!important;
  margin-right: 15px;
  margin-left: 15px;
}

.page-item.active .page-link {
  background: #325EEA!important;
  color: white!important;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  font-size: 15px;
}

.page-link {
  font-weight: 500;
  background: transparent!important;
  color: #869AB8!important;
}

.history {
  display: inline-table;
  min-width: 100%;
  min-height: calc(100vh - 110px);
  padding-left: 40px;
  padding-right: 40px;
}

.history label {
  font-weight: 500;
  font-size: 12px;
}

/*table {
  padding-left: 20px;
  margin-top: 40px;
  background: #FFFFFF;
  border: 1px solid rgba(22,28,45,0.10);
  box-shadow: 0 4px 11px 0 rgba(0,0,0,0.13);
  border-radius: 7px!important;
}

td {
  height: 56px;
  line-height: 56px!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  height: 60px;
  color: #869AB8;
  font-weight: 500;
  line-height: 30px!important;
}

tr:nth-child(2n) {
  background: #F6F8FE;
}*/

.history .soft {
  margin-top: 30px;
  font-size: 15px;
  font-weight: bold;
}


.modal-body.builder
{
    max-height: calc(100vh - 100px);
    overflow: auto;
}

#myNavbar
{
    margin-left: 45px;
}

.box
{
    border-radius: 15px;
    border: 1px solid red;
    background: rgb(250, 250, 250);
    padding: 30px;
}

.sky-b {
    background: #3D4C69 url("https://www.utm-builder.com/wp-content/uploads/2015/02/background2.jpg") no-repeat;
    background-size: cover;
}
.blue-b {
   background: #FBFBFB;
}
.grass-b
{
    background: rgb(128, 188, 189) url("https://www.utm-builder.com/wp-content/uploads/2015/02/background33.jpg") no-repeat;
    background-size: cover;
}

.purple-b
{
    background-color: #FBFBFB;
    background-position: center bottom;
    background-repeat: no-repeat;
}

@media (min-width: 768px)
{
    .modal-dialog.wide
    {
        width: 700px;
        margin: 30px auto;
    }
}

/*
 EXTENSION IMPORT
*/
.modal-clear
{
    display: block;
    background: rgba(200, 200, 200, 0.5);
}

.modal-content-empty
{
    background: rgba(255, 255, 255, 0);
    box-shadow: none;
    border: none;
}

.modal-clear-text
{
    background: white;
    border-radius: 20px;
    padding: 5px;
}

.input-group-addon {
  background: #869AB8;
  color: white;
}

.field-input
{
    padding-top: 5px;
}

.field-input .input-group-addon
{
    font-size: 14px!important;
}

.btns-list
{
    padding-top: 5px;
}

button:focus {
  outline: none !important;
  outline-offset: none !important;
}

.btns-list button {
  min-width: 66px;
  height: 33px;
  line-height: 15px;
  margin-right: 10px!important;
  margin-bottom: 10px!important;
}


.field
{
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(22,28,45,0.10);
}

.fields strong
{
    font-weight: 500;
    font-size: 16.4px;
    color: #869AB8!important;
    letter-spacing: 0.14px;
}
.fields {
    padding-left: 20px;
    padding-right: 20px;
}

.btn-basic
{
    margin-right: 6px;
    border-width: 0.5px;
    border-radius: 25px;
    background-color: white;
    color: #2C3E50;
}

.btn-basic.disabled
{
    color: rgba(0, 0, 0, 0.3);
    border-color: rgb(240, 240, 240);
}

.min-form
{
    max-width: 50%
}

.row-title
{
    vertical-align: middle !important;
    border-right: 1px dashed black !important;
    max-width: 10%;
    padding-left: 0px !important;
    padding-right: 5px !important;
}

#status
{
    /* avoid an excessively wide status text */
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 400px;
}

#wrap
{
    padding: 30px;
    min-width: 800px;
}

.header
{
    vertical-align: middle;
    text-align: center;
    padding: 8px;
    position: fixed;
    width: 100%;
    top: 0px;
    background: rgb(100, 100, 100);
    min-height: 20px;
    z-index: 999;
}

.button-header
{
    width: 70%;
    float: left;
}

.right-header
{
    width: 20%;
    float: left;
}

.centered
{
    margin: auto;
}

.options-header
{
    text-shadow: 4px 4px 7px grey;
    width: 10%;
    float: left;
    font-size: 1.8em;
}

.options-header:hover
{
    text-shadow: 8px 8px 7px grey;
}

.getUrl
{
    width: 90%;
    min-width: 200px;
    font-weight: bold;
}

.int
{
    display: none;
}


@media (min-width: 1200px)
{
    .container
    {
        width: 1000px;
    }
}
a, a:hover {
  color: #2f5ce6;
}

h1 {
  font-size: 31px!important;
}

h2 {
  font-size: 25px !important;
}

h5 {
  font-size: 18px;
}
.soft {
  font-size: 14px;
}


h5, .soft {
  color: #869AB8;
  font-weight: 400;
} 

.form-control {
  height:38px;
  border-width: 1px;
}

.planContainer {
  position: relative;
  flex-grow: 1;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px 20px;
  min-width: 300px;
  max-width: 400px;
  height: 522px;
  border: 1px solid rgba(22,28,45,0.10);
    border-radius: 7px;
    -webkit-box-shadow: -2px 10px 19px -9px rgba(0,0,0,0.1);
-moz-box-shadow: -2px 10px 19px -9px rgba(0,0,0,0.1);
box-shadow: 0 5px 9px 0 rgba(0,0,0,0.06);
}

.planContainer button {
    left: 21px;
    width: 88%;
    position: absolute;
    bottom: 30px;
}

@media (max-width: 1000px)
{
    
    .tutorial {
      display: inline-table;
    }
}

 .clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

.planContainer .list-group-item {
  margin-top: 10px;
    margin-bottom: 10px;
  color : #47576b;
  font-weight: 500;
  text-align: left;
  border:none;
}

.planContainer .list-group-item:not(:last-child) {
  border-bottom: 1px solid rgba(134, 154, 184, 0.23);;
}



.tutorial .breadcrumb .breadcrumb-item::before {
  content: "";
}

.tutorial .breadcrumb {
  background-color: #ffff; 
  font-weight: 500;
}

.tutorial .breadcrumb-item a {
  font-size: 17px!important;
  color: #4F6690!important;
  text-decoration: none;
  margin-left: 10px;
  letter-spacing: 0.49px;
}

.tutorial .breadcrumb-item a:hover {
  color: rgb(52, 92, 235)!important;
}

.logoTutorialNav {
  float:left;
  height: 42px;
    top: 0;
    margin-top: 0;
    width: auto;
    margin-left: 75px;
}

.close-times.fa-times {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #869AB8;
  cursor: pointer;
}

.close-times.fa-times:hover {
  color: rgb(52, 92, 235)!important;
}

.infoModal .btn:not(.btn-primary):hover, .warningModal .btn:not(.btn-primary):hover {
  color: rgb(52, 92, 235)!important;
}

.left {
  float:left;
}

.tutorial .control-label {
  margin-bottom: 0;
  font-size: 12px;
}

.tutorial input {
  margin-bottom: 20px;
  height: 38px;
  border: 1px solid #D5DAE2;
  border-radius: 5px;
}

.btn.btn-deleteable {
  outline: none;
  margin-right: 10px;
  background-color: white;
  color: #335ee9;
  background-color: #EBF0FD;
  border-radius: 50px!important;
  padding: 0;
  padding-left: 13px;
  padding-right: 20px;
}
.btn.btn-deleteable:after {
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
    content: "\f00d";
    margin-left: 10px;
}


.btn.btn-selectable.square {
  border-radius: 7px!important;
}

.btn.btn-selectable.selected {
  outline: none;
  color: white;
  margin-right: 10px;
  background-color: #06C9A8;
  border: none;
  border-radius: 50px;
  padding: 0 20px 0 13px;
}



.btn.btn-selectable.unselected:hover {
  background-color: #fbfbfb;
}

.btn.btn-selectable.selected:hover {
  background-color: #04bb9b;
}



.btn.btn-selectable.selected:before {
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
    content: "\f00c";
     float: right;
    padding: 0 0.8em;
    pointer-events: none;
    text-align: center;
    width: 1em;
}

.btn.btn-selectable.unselected {
  outline: none;
  margin-right: 10px;
  background-color: white;
  color: #05c9a7;
  border: 1px solid #06C9A8;
  border-radius: 50px;
  padding: 0 20px 0 13px;
}

.btn.btn-selectable.unselected:not(.square):before {
    content: "\f055";
    font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
    float: right;
    padding: 0 0.7em;
    pointer-events: none;
    text-align: center;
    width: 1em;
}


.tutorial .fa-question-circle {
  cursor: pointer;
  font-size: 20px;
    margin-bottom: 0px;
    float: right;
    color: #2e5ce6;
}

.tutorial .fa-question-circle:hover {
    color: #84a2fd;
}

.tutorial .fa-arrow-left {
  cursor: pointer;
  font-size: 14pt;
    color: #335ee9;
    background-color: #eaf0fc;
    position: absolute;
    left: 40px;
    top: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.input-group-prepend, .input-group-append {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.inputWrapper { 
  position: relative;
}
i.enterText {
    color: #91a3bd;
    position: absolute;
    top: 25px;
    right: 20px;
}


.tutorial .fa-arrow-left:hover {
  transition: 0.5s;
    color: white;
    background-color: #335ee9;
}



.infoModal, .warningModal, .actionModal {
  text-align: start;
  z-index: 1059;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(78, 76, 76, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.warningModal .background {
  min-width: 400px;
    background-color: white;
    position: relative;
    max-width: 500px;
    padding: 10px 30px 30px;
    border-radius: 7px;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.13);
}

.actionModal .background {
  background-color: white;
  position: relative;
  width: 604px;
    padding: 40px 40px 20px 40px;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.13);
  border-radius: 7px;
}

.actionModal .bottomBar {
  padding-top: 40px;
}

.actionModal h1 {
  padding-bottom: 20px;
}

.actionModal .soft {
  font-size: 17px;
}

.infoModal .title {
  margin-top: 20px;
  margin-bottom: 20px;
}


.infoModal .background {
  background-color: white;
  position: relative;
  width: 604px;
  padding: 15px 20px 15px 20px;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.13);
  border-radius: 7px;
}

.infoModal .soft {
  font-size: 17px;
}



